
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { getCookie, setCookie } from "../../utils/tools";
import { client } from '@/client';
import { ElMessage, ElMessageBox } from 'element-plus';
import { AdminData } from "@/shared/types/AdminData";
import { defineComponent } from 'vue';
export default defineComponent({
  name: "admin-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      keywords: "",
      tableData:[] as AdminData[],
      multipleSelection: [],
      count: 0,
      cur: 1,
      username: "",
    };
  },
  computed: {},
  created() {
    this.username = getCookie("username")||"";
    this.list();
  },
  methods: {
    //查询
    async list() {
      try {
        let res = await client.callApi("admin/List",{cur:this.cur,pageSize:10});
        if (res.isSucc) {

          this.tableData = [...res.res.list];
          this.count = res.res.count;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //翻页
    handleCurrentChange(e: number) {
      this.cur = e;
      this.list();
    },

    toggleSelection(rows: any[]) {
          let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row: any) => {
          if (refs) {
            refs.toggleRowSelection(row);
          }
        });
      } else {
            refs.clearSelection();
      }
    },

    handleSelectionChange(val: never[]) {
      this.multipleSelection = val;
    },

    //编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "admin-edit", params: { id: id } });
    },

    //删除
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
         ElMessageBox.confirm("这么疯狂，要删管理员？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await client.callApi("admin/Delete",{id:id});
            // let res = await del(id);
            if (res.isSucc) {
              ElMessage({
                message: "删除成功 ^_^",
                type: "success",
              });
              this.list();
            }
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "已取消删除",
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
